import React, { useRef } from "react"
import { graphql } from 'gatsby'
import loadable from '@loadable/component'

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"
import BlogPageHeader from '../components/BlogPageHeader'
const BlogList = loadable(() => import('../components/BlogList'))

const IndexPage = ({ data }) => {
  const sectionTwo = useRef()

  function scrollToSectionTwo() {
    sectionTwo.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <FadeIn>
      <SEO
        title="The Juice | Industry Blog"
        description="Features, insights and opinions worth reading."
      />
      <BlogPageHeader scrollToSection={scrollToSectionTwo} />
      <div ref={sectionTwo}>
        <BlogList blogPosts={data.contentfulBlogsList.blogPosts} />
      </div>
    </FadeIn>
  )
}

export const query = graphql`
query BlogPageQuery {
  contentfulBlogsList(name: {eq: "Blogpage Blog Posts"}) {
    id
    blogPosts {
      id
      slug
      title
      subtitle
    }
  }
}
`

export default IndexPage
