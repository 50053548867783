// extracted by mini-css-extract-plugin
export var hideForMobile = "BlogPageHeader-module--hide-for-mobile--QpcYA";
export var hideForTablet = "BlogPageHeader-module--hide-for-tablet--qBJYg";
export var hideForLaptop = "BlogPageHeader-module--hide-for-laptop--NCTjj";
export var hideForDesktop = "BlogPageHeader-module--hide-for-desktop--m-QW5";
export var hideForExtraLarge = "BlogPageHeader-module--hide-for-extra-large--z0TPT";
export var showForMobileOnly = "BlogPageHeader-module--show-for-mobile-only--SvES2";
export var showForTabletOnly = "BlogPageHeader-module--show-for-tablet-only--HJ091";
export var showForLaptopOnly = "BlogPageHeader-module--show-for-laptop-only--vu2Et";
export var showForDesktopOnly = "BlogPageHeader-module--show-for-desktop-only--uyJem";
export var showForExtraLargeOnly = "BlogPageHeader-module--show-for-extra-large-only--RwNXX";
export var fullPageContainer = "BlogPageHeader-module--full-page-container--QHTDt";
export var container = "BlogPageHeader-module--container--VOpTJ";
export var titleContainer = "BlogPageHeader-module--titleContainer--967dg";
export var title = "BlogPageHeader-module--title--2mzjh";