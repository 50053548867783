import React from "react"

import * as styles from "./BlogPageHeader.module.scss"
import loadable from "@loadable/component"
import MouseDownIcon from '../../assets/svgs/mouse-down.svg'
const FadeUpAndIn = loadable(() => import("../Animated/FadeUpAndIn"))

function BlogPageHeader({ scrollToSection }) {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <FadeUpAndIn delay={1.8}>
          <div className={styles.title}>The Juice</div>
        </FadeUpAndIn>
      </div>
      <MouseDownIcon onClick={scrollToSection} style={{ cursor: 'pointer' }} />
    </div>
  )
}

export default BlogPageHeader
